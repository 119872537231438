import React from 'react';

import Address from '../address';
import GoogleMaps from '../map';

import './contact-page.sass';

const ContactPage = () => {
  return (
    <div className="layout-wrap">
      <section className="contact-page">
        <div className="container">
          <div className="contact-info">
            <div className="contact-address">
              <Address />
            </div>
            <div id="map-wrapper">
              <GoogleMaps />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;