import React from 'react';

import './address.sass';

const Address = () => {
  return (
    <ul className="address-list">
      <li className="address-item schedule">
        <h5 className="address-caption sm-caption">Години роботи</h5>
        <p className="schedule-info">Пн-Нд 9.00-20.00</p>
      </li>
      <li className="address-item location">
        <h5 className="address-caption sm-caption">Адреса</h5>
        <address>
          <a className="address-link gmap-link" href="https://goo.gl/maps/V2frm5YKimhVomSX7" rel="noreferrer" target="_blank">Балтійський провулок, 3, Київ, 04073</a>
        </address>
      </li>
      <li className="address-item contacts">
        <h5 className="address-caption sm-caption">Контакти</h5>
        <address>
          <a className="address-link number-link" href="tel:+380988606868">+38 (098) 860 68 68</a>
        </address>
        <address>
          <a className="address-link instagram-link" href="https://instagram.com/childent.clinic" rel="noreferrer" target="_blank">
            Instagram
            <span className="social-media-profile"> @childent.clinic</span>
          </a>
        </address>
        <address>
          <a className="address-link telegram-link" href="https://t.me/Childent" rel="noreferrer" target="_blank">
            Telegram
            <span className="social-media-profile"> @Childent</span>
          </a>
        </address>
        <address>
          <a className="address-link viber-link" href="viber://add?number=380988606868" rel="noreferrer" target="_blank">
            Viber
            <span className="social-media-profile"> +38 (098) 860 68 68</span>
          </a>
        </address>
        <address>
          <a className="address-link email-link" href="mailto:childentclinic@gmail.com" rel="noreferrer" target="_blank">childentclinic@gmail.com</a>
        </address>
      </li>
    </ul>
  );
};

export default Address;