import React from 'react';

import TeamList from '../team-list';

const AboutUsPage = () => {
  return (
    <div className="layout-wrap">
      <TeamList />
    </div>
  );
};

export default AboutUsPage;