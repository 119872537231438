import React from 'react';

import './price-list.sass';

import priceData from './priceData.json';

const PriceList = () => {

  const priceList = priceData.data.map((dataItem) => {

    const priceListItems = dataItem.serviceList.map((serviceItem) => {
      return (
        <li className="price-item" key={serviceItem.serviceName}>
          <p className="price-item-name">{serviceItem.serviceName}</p>
          {
            serviceItem.hasOwnProperty("serviceOldPrice") ?
            (
              <p className="price-item-cost">
                <span className="old-price">{serviceItem.serviceOldPrice}</span>
                <span> {serviceItem.servicePrice}</span>
              </p>
            ) : (
              <p className="price-item-cost">{serviceItem.servicePrice}</p>
            )
          }
        </li>
      );
    });

    return (
      <React.Fragment key={dataItem.serviceListName}>
        <h4 className="price-caption">{dataItem.serviceListName}</h4>
        <ul className="price-list">
          {priceListItems}
        </ul>
      </React.Fragment>
    );
  });

  return (
    <section className="price-section">
      <div className="container">

        <h4 className="price-caption">Діагностика</h4>
        <ul className="price-list">
          <li className="price-item">
            <p className="price-item-name">Консультація</p>
            <p className="price-item-cost">
              <span className="old-price">100 грн.</span>
              <span className="text-uppercase"> безкоштовно</span>
            </p>
          </li>
          <li className="price-item">
            <p className="price-item-name">Діагностика та план лікування</p>
            <p className="price-item-cost">
              <span className="old-price">300 грн.</span>
              <span className="text-uppercase"> безкоштовно</span>
            </p>
          </li>
        </ul>

        {priceList}
      </div>
    </section>
  );
};

export default PriceList;