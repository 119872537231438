import React from 'react';

import PriceList from '../price-list';

const ServicesPage = () => {
  return (
    <div className="layout-wrap">
      <PriceList />
    </div>
  );
};

export default ServicesPage;