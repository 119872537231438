import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';

import './header.sass';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      scrollPosition: window.scrollY
    };
    this.openMenu = this.openMenu.bind(this);
    this.updateScrollPosition = this.updateScrollPosition.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollPosition);
  }

  openMenu(e) {
    e.preventDefault();
    this.setState({
      open: !this.state.open
    });
  }

  updateScrollPosition() {
    this.setState({
      scrollPosition: window.scrollY
    });
  }

  render() {
    return (
      <header className={this.state.scrollPosition === 0 ? "header" : "header scrolled"}>
        <div className="container">
          <Link to="/" className="logo-link">
            <img className="logo img-fluid" src="./logo192.png" alt="Childent dental clinic logo" />
          </Link>
          <nav className={this.state.open ? "nav opened" : "nav"}>
            <ul className="nav-list">
              <li onClick={this.openMenu} className="nav-item">
                <NavLink className="nav-link" to="/">Головна</NavLink>
              </li>
              <li onClick={this.openMenu} className="nav-item">
                <NavLink className="nav-link" to="/services">Послуги</NavLink>
              </li>
              <li onClick={this.openMenu} className="nav-item">
                <NavLink className="nav-link" to="/about">Про нас</NavLink>
              </li>
              <li onClick={this.openMenu} className="nav-item">
                <NavLink className="nav-link" to="/contact">Контактна інформація</NavLink>
              </li>
            </ul>
            <div onClick={this.openMenu} className="dark-background" />
          </nav>
          <a onClick={this.openMenu} className={this.state.open ? "btn-toggle-menu active" : "btn-toggle-menu"}>
            <svg className="btn-svg" viewBox="0 0 24 24" stroke="currentColor">
              <g transform="translate(12,12)">
                <path className="icon-bar" d="M-9 -5 L9 -5" fill="yellow" strokeWidth="1" />
                <path className="icon-bar" d="M-9 0 L9 0" fill="yellow" strokeWidth="1" />
                <path className="icon-bar" d="M-9 5 L9 5" fill="yellow" strokeWidth="1" />
              </g>
            </svg>
          </a>
        </div>
      </header>
    );
  };
};