import React from 'react';

import './app.sass';

import Header from '../header';
import Footer from '../footer';

import {
  HomePage,
  ServicesPage,
  AboutUsPage,
  ContactPage,
  NoPage
} from '../pages';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from "./scroll-to-top";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="childent-app">
        <Header />

        <Routes>
          <Route index element={<HomePage />} />
          <Route path="services" element={<ServicesPage />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="*" element={<NoPage />} />
        </Routes>

        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;