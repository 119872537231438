import React from 'react';

import './no-page.sass';

const NoPage = () => {
  return (
    <div className="layout-wrap">
      <section className="welcome welcome-no-page">
        <p className="welcome-title">Такої сторінки не існує</p>
      </section>
    </div>
  );
};

export default NoPage;