import React from 'react';

import './team-list.sass';

const TeamList = () => {
  return (
    <section className="team-section">
      <div className="container">
        <h2 className="lg-caption">Спеціалісти</h2>
        <ul className="team-list">
          <li className="team-item">
            <img className="team-item-photo img-fluid" src="./img/team/contact-1.jpg" alt="Армен Чілінагрян" />
            <p className="team-item-name">Армен Чілінгарян</p>
          </li>
          <li className="team-item">
            <img className="team-item-photo img-fluid" src="./img/team/contact-2.jpg" alt="Наталія Абара" />
            <p className="team-item-name">Наталія Абара</p>
          </li>
          <li className="team-item">
            <img className="team-item-photo img-fluid" src="./img/team/contact-3.jpg" alt="Карен Чілінгарян" />
            <p className="team-item-name">Карен Чілінгарян</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default TeamList;