import React from 'react';

import './home-page.sass';

const HomePage = () => {
  return (
    <div className="layout-wrap">
      <section className="welcome welcome-home">
        <div className="container">
          <h1 className="welcome-title">
            <span>Професійний підхід.</span>
            <span>Комфорт для пацієнтів.</span>
            <span>Комплексне лікування.</span>
          </h1>
        </div>
      </section>
    </div>
  );
};

export default HomePage;